var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offer-header tw-relative tw-flex tw-items-center tw-justify-between tw-bg-gray-200 tw-px-9 tw-py-2 tw-rounded-tl tw-rounded-tr",class:{
    'offer-header--danger': _vm.inDanger
  }},[(_vm.isProposalView && _vm.proposal && (_vm.proposal.status === 'accepted' || !_vm.isOfferAvailableForBooking))?[(_vm.proposal.status === 'accepted')?_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-flex-1"},[_c('h1',{staticClass:"tw-text-xl tw-font-medium tw-mb-0 tw-text-blue-500",domProps:{"textContent":_vm._s(_vm.$tc('proposal_list.proposals.accepted', 1))}}),(_vm.isMissionOwner)?_c('ui-button',{staticClass:"tw-rounded-full tw-border-none tw-mr-3 tw-flex-grow-0 tw-text-white",attrs:{"to":{
          name: 'Mission',
          params: {
            uuid: _vm.proposal.mission && _vm.proposal.mission.uuid
          }
        },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('show_offer_detail')))+" ")]):_vm._e()],1):(!_vm.isOfferAvailableForBooking)?_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-flex-1"},[_c('h1',{staticClass:"tw-text-xl tw-font-medium tw-mb-0 tw-text-white",domProps:{"textContent":_vm._s(_vm.$t('offers.titles.offer_unavailable'))}})]):_vm._e()]:[(_vm.canHideOffer)?_c('ui-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"offer-header__hide tw-mr-3 tw-flex-grow-0",attrs:{"title":_vm._f("capitalize")(_vm.$t('hide_offer')),"disabled":!_vm.isOfferAvailableForBooking,"variant":"light","fab":""},on:{"click":_vm.askHide}},[_c('div',{staticClass:"tw-flex tw-items-center",class:{
          'tw-text-blue-500' : _vm.isOfferAvailableForBooking
        }},[_c('ui-ctk-icon',{staticClass:"tw-text-3xl tw-block",attrs:{"name":"hide"}})],1)]):_vm._e(),_c('div',{staticClass:"tw-flex-1"},[_c('h1',{staticClass:"tw-text-xl tw-font-medium tw-mb-0 tw-text-blue-500",domProps:{"textContent":_vm._s(_vm.$t('offer_with_ref', {
          ref: _vm.reference
        }))}}),_c('div',{staticClass:"tw-text-gray-700",domProps:{"textContent":_vm._s(_vm.$t('app.values.expires_at', {
          date: _vm.$moment(_vm.offer.expires_at).format('LLL')
        }))}})])],_c('ui-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"offer-header__close tw-top-3 tw-right-3 tw-flex-grow-0",attrs:{"title":_vm._f("capitalize")(_vm.$t('close')),"variant":"light","fab":""},on:{"click":_vm.closeOffer}},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('ui-ctk-icon',{staticClass:"tw-text-3xl tw-block",attrs:{"name":"close"}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }