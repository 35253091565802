<template>
  <div
    v-if="hasAccessibilityIssue"
    class="offer-accessibility tw-flex tw-bg-yellow-300 tw-rounded tw-px-4 tw-py-3"
  >
    <div class="tw-mr-1">
      <ui-ctk-icon
        name="warning-full"
        class="tw-text-3xl tw-text-yellow-500 tw--ml-1 tw--mt-1"
        data-test="warning-icon"
      />
    </div>
    <div class="tw-flex tw-flex-col tw-text-yellow-700">
      <div
        class="tw-uppercase tw-text-xs tw-font-bold"
        v-text="title"
        data-test="title"
      />
      <p
        v-for="(accessibility, k) in accessibilitiesContent"
        :key="k"
        v-text="accessibility"
        class="tw-m-0"
        data-test="description"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent, toRefs, computed, onMounted } from '@vue/composition-api'

  import Hotjar from '@/plugins/VueHotjar'
  import useI18n from '@/composables/useI18n'

  /**
   * @module component - OfferAccessibility
   */
  export default defineComponent({
    name: 'OfferAccessibility',
    props: {
      offer: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    },
    setup (props) {
      const { offer, direction } = toRefs(props)
      const i18n = useI18n()

      /**
       * @function getAccessibilityPriceLine
       * @param {string} direction
       * @returns {{
       *   comment: string
       * } | null}
       */
      function getAccessibilityPriceLine (direction) {
        if (!offer.value.pricing || !offer.value.pricing.price_lines) return null

        // @ts-ignore
        return offer.value.pricing.price_lines.find(line => line.key === `${direction}_address_accessibility`)
      }

      /**
       * This would returns true if either:
       * - an accessibility has manually been set
       * - or the offer has a price line accessibility
       */
      const hasAccessibilityIssue = computed(() => {
        const { accessibilities } = offer.value[direction.value].address
        if (accessibilities) return true
        else if (getAccessibilityPriceLine(direction.value)) return true

        return false
      })

      /**
       * Returns the appropriate wording according to the vehicle type.
       * In the case of a accessibility price line, returns the price line comment if available.
       */
      const accessibilityContent = computed(() => {
        const { accessibility } = offer.value[direction.value].address
        const priceLine = getAccessibilityPriceLine(direction.value)

        if (accessibility) {
          return accessibility.vehicle_type.key === 'lightweight_vehicle'
            ? i18n.value.t('offers.values.accessibility.lightweight_vehicle')
            : i18n.value.t('offers.values.accessibility.carrier_truck')
        } else if (priceLine) {
          return priceLine.comment
        }

        return null
      })

      const accessibilitiesContent = computed(() => {
        const { accessibilities } = offer.value[direction.value].address
        const priceLine = getAccessibilityPriceLine(direction.value)

        if (accessibilities) {
          return accessibilities
        } else if (priceLine) {
          return [priceLine.comment]
        }

        return null
      })

      const title = computed(() => {
        return direction.value === 'pickup'
          ? i18n.value.t('app.labels.accessibility.pickup')
          : i18n.value.t('app.labels.accessibility.delivery')
      })

      onMounted(() => {
        Hotjar.tag('Offer With Complex Address')
      })

      return {
        title,
        hasAccessibilityIssue,
        accessibilityContent,
        accessibilitiesContent
      }
    }
  })
</script>

<style lang="scss" scoped>
.offer-accessibility {
  position: relative;
}
.offer-accessibility::before {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 229, 203, var(--tw-bg-opacity));
  height: 1rem;
  position: absolute;
  width: 1rem;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  --tw-rotate: 45deg;
  content: '';
  left: 20px;
  top: -7px;
}
</style>
