<template>
  <offer-section
    class="offer-pricing-section tw-flex tw-flex-col"
  >
    <template
      #title
    >
      <offer-section-title
        :title="$t('offer.title.price')"
      >
        <ui-ctk-icon
          name="budget"
          class="tw-text-blue-500 tw-text-3xl"
        />
      </offer-section-title>
    </template>

    <template
      v-if="offer.pricing"
    >
      <!-- Fare -->
      <div
        class="tw-flex tw-items-center tw-flex-1 tw-justify-between tw-mb-2"
        data-test="fare"
      >
        <span
          class="tw-my-auto tw-mx-0 tw-uppercase tw-text-gray-700"
          v-text="$t('app.pricing.your_fare_without_tax')"
          data-test="label"
        />
        <price-chip
          class="price-value tw-py-1 tw-py-2 tw-mr-0 tw-my-auto tw-ml-4 tw-text-base"
          data-test="price"
        >
          {{ (offer.pricing.carrier_price || offer.pricing.price) | currency(offer.pricing.currency, $i18n.locale) }}
        </price-chip>
      </div>
    </template>
  </offer-section>
</template>

<script>
  import { defineComponent, computed, toRefs } from '@vue/composition-api'

  import OfferSection from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSection/index.vue'
  import OfferSectionTitle from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSection/OfferSectionTitle/index.vue'
  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout.vue'
  import PricingLines from '@/components/CtkPricing/_subs/PricingLines/index.vue'
  import PriceChip from '@/components/CtkPricing/_subs/PriceTotal/_subs/PriceChip/index.vue'

  /**
   * @module component - OfferPricingSection
   */
  export default defineComponent({
    name: 'OfferPricingSection',
    components: {
      OfferSection,
      OfferSectionTitle,
      PricingLayout,
      PricingLines,
      PriceChip
    },
    props: {
      offer: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { offer } = toRefs(props)

      const isAvailableForBooking = computed(() => {
        if (typeof offer.value.available_for_booking === 'undefined') return true
        if (offer.value.available_for_booking) return true

        return false
      })

      /**
       * Returns only the travel line
       */
      const fareLines = computed(() => {
        return offer.value.pricing.price_lines
          // @ts-ignore
          .filter(line => line.key === 'travel')
      })

      /**
       * Returns all the price lines except the travel one, which is already
       * included in the fare lines.
       */
      const specificitesLines = computed(() => {
        return offer.value.pricing.price_lines
          // @ts-ignore
          .filter(line => line.key !== 'travel')
      })

      return {
        fareLines,
        specificitesLines,
        isAvailableForBooking
      }
    }
  })
</script>

<style lang="scss">
.offer-pricing-section .pricing-layout {
  padding-left: 0px;
  padding-right: 0px;
}
.offer-pricing-section .pricing-lines {
  border-style: none;
  padding-top: 0px;
  padding-bottom: 0px;
}
.offer-pricing-section .pricing-lines-label {
  --tw-text-opacity: 1;
  color: rgba(48, 49, 68, var(--tw-text-opacity));
}
</style>
