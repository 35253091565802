<template>
  <offer-section
    class="offer-load-section"
  >
    <template
      #title
    >
      <offer-section-title
        :title="$tc('good', 1) | capitalize"
      >
        <ui-ctk-icon
          name="pallets"
          class="tw-text-blue-500 tw-text-3xl"
        />
      </offer-section-title>
    </template>

    <div class="offer-load-section__items tw-flex tw-flex-wrap tw-mb-6">
      <!-- type -->
      <offer-group-item
        :title="$t('app.labels.load.type') | capitalize"
        data-test="type"
      >
        <template
          v-if="offer.load.type === 'custom'"
        >
          {{ $t('prepared_goods') | capitalize }}
        </template>
        <template
          v-else-if="offer.load.type === 'pallets'"
        >
          {{ $t('pallets') | capitalize }}
        </template>
        <template
          v-else
        >
          {{ $t('unprepared_goods') | capitalize }}
        </template>
      </offer-group-item>

      <!-- MPL & format -->
      <offer-group-item
        v-if="shouldDisplayMpl"
        :title="$t('app.labels.load.mpl') | capitalize"
        data-test="format"
      >
        <template>
          {{ $t('lm', { lm: loadLmValue }) }}
        </template>
      </offer-group-item>
      <template
        v-else
      >
        <offer-group-item
          :title="$t('app.labels.load.format') | capitalize"
          data-test="format"
        >
          <template
            v-if="offer.load.type === 'pallets'"
          >
            {{ offer.load.format }} cm
          </template>
          <template
            v-else
          >
            {{ `${offer.load.width}x${offer.load.length} cm` }}
          </template>
        </offer-group-item>
        <offer-group-item
          v-if="offer.load.type === 'pallets'"
          :title="$t('app.labels.load.quantity') | capitalize"
          data-test="quantity"
        >
          {{ $n(offer.load.quantity) }}
        </offer-group-item>
      </template>

      <!-- height -->
      <offer-group-item
        :title="$t('app.labels.load.max_height') | capitalize"
        data-test="height"
      >
        <span
          v-text="`${$n(offer.load.height)} cm`"
        />
      </offer-group-item>

      <!-- weight -->
      <offer-group-item
        :title="$t('app.labels.load.weight') | capitalize"
        data-test="weight"
      >
        <span
          v-text="$t('weight_unit', {
            weight: $n(offer.load.weight)
          })"
        />
      </offer-group-item>
    </div>
    <offer-specificity-group
      v-if="offer.load.description || adr || adjustment"
      class="lg:tw-max-w-80 tw-mt-6"
      data-test="load-group"
    >
      <offer-specificity-item
        v-if="adjustment"
        :icon="'adjust'"
        :title="$t('app.labels.adjustment')"
        :variant="'info'"
        :content="adjustment.comment"
        data-test="adjustment"
      />
      <offer-specificity-item
        v-if="adr"
        :icon="'skull'"
        :title="$t('app.labels.adr_load')"
        :variant="'info'"
        :content="adr.comment"
        data-test="adr"
      />
      <offer-specificity-item
        v-if="offer.load.description"
        :icon="'comment'"
        :title="$t('comment')"
        :content="offer.load.description"
        data-test="description"
      />
    </offer-specificity-group>
    <offer-group-item
      v-if="hasPallets"
      :title="$t('app.labels.load.pallets_detail')"
      class="tw-mt-4"
      data-test="pallets"
    >
      <div class="tw-flex tw-flex-col md:tw-flex-row">
        <offer-load-pallet
          v-for="(pallet, k) in offer.load.pallets"
          :key="k"
          :pallet="pallet"
          class="md:not-last:tw-mr-3 tw-mb-3"
          data-test="pallet"
        />
      </div>
    </offer-group-item>
  </offer-section>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import OfferSection from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSection/index.vue'
  import OfferSectionTitle from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSection/OfferSectionTitle/index.vue'
  import OfferGroupItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferGroupItem/index.vue'
  import OfferSpecificityGroup from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSpecificityGroup/index.vue'
  import OfferSpecificityItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSpecificityGroup/_subs/OfferSpecificityItem/index.vue'
  import OfferLoadPallet from './_subs/OfferLoadPallet/index.vue'

  /**
   * @module component - OfferLoadSection
   */
  export default defineComponent({
    name: 'OfferLoadSection',
    components: {
      OfferSection,
      OfferSectionTitle,
      OfferGroupItem,
      OfferSpecificityGroup,
      OfferSpecificityItem,
      OfferLoadPallet
    },
    props: {
      offer: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { offer } = toRefs(props)

      /**
       * @function getPriceLine
       * @param {string} lineKey
       * @returns {{
       *   key: string
       * } | undefined}
       */
      function getPriceLine (lineKey) {
        if (!offer.value.pricing || !offer.value.pricing.price_lines) return

        // @ts-ignore
        return offer.value.pricing?.price_lines?.find(line => line.key === lineKey)
      }

      const adr = computed(() => getPriceLine('adr_dangerous_goods_by_road'))
      const adjustment = computed(() => getPriceLine('shipper_load_incorrect_configuration'))

      /** @type {import('@vue/composition-api').ComputedRef<boolean>} */
      const hasPallets = computed(() => !!offer.value.load.pallets && offer.value.load.pallets.length)

      const loadLmValue = computed(() => {
        const offerData = offer.value
        const offerLinearMeters = !!offerData && !!offerData.load && offerData.load.linear_meters
        return offerLinearMeters || null
      })

      const shouldDisplayMpl = computed(() => {
        const offerData = offer.value
        const isCustomOffer = !!offerData && !!offerData.load && !!offerData.load.type && offerData.load.type === 'custom'
        const isMultiPallet = hasPallets.value ? offerData.load.pallets.length > 1 : false
        return isCustomOffer && isMultiPallet && !!loadLmValue
      })

      return {
        adr,
        adjustment,
        hasPallets,
        shouldDisplayMpl,
        loadLmValue
      }
    }
  })
</script>

<style lang="scss" scoped>
.offer-load-section__items {
  margin: -1rem;
}
.offer-load-section__items .offer-group-item {
  margin-bottom: 0.5rem;
  margin: 1rem;
  flex: 1 0 calc(45% - 1rem);
}
@media (min-width: 770px) {
  .offer-load-section__items .offer-group-item {
    flex: 1 0 calc(18% - 1rem);
  }
  .offer-load-section__items .offer-group-item:not(:last-child) {
    position: relative;
  }
  .offer-load-section__items .offer-group-item:not(:last-child)::after {
    --tw-border-opacity: 1;
    border-color: rgba(216, 216, 216, var(--tw-border-opacity));
    border-style: solid;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 0px;
    height: 100%;
    position: absolute;
    content: '';
    width: 1px;
    right: -1rem;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
