<template>
  <div class="offer-timeslots tw-flex">
    <offer-group-item
      :title="$t('app.labels.date')"
      class="tw-w-1/2"
      data-test="date"
    >
      {{ date }}
    </offer-group-item>
    <offer-group-item
      :title="$t('app.labels.schedule')"
      class="tw-w-1/2"
      data-test="schedule"
    >
      {{ schedule }}
    </offer-group-item>
  </div>
</template>

<script>
  import { defineComponent, computed, toRefs } from '@vue/composition-api'

  import OfferGroupItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferGroupItem/index.vue'
  import useI18n from '@/composables/useI18n'
  import useMoment from '@/composables/useMoment'

  export default defineComponent({
    components: {
      OfferGroupItem
    },
    props: {
      offer: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    },
    setup (props) {
      const { offer, direction } = toRefs(props)
      const i18n = useI18n()
      const moment = useMoment()

      const date = computed(() => {
        const { flexible, date } = offer.value[direction.value].time_slot
        const formatteDate = moment.value(date).format('ll')

        const isFlexible = flexible === true
        const flexibleLabel = direction.value === 'pickup'
          ? i18n.value.t('offers.values.from_the', {
            date: formatteDate
          })
          : i18n.value.t('offers.values.until', {
            date: formatteDate
          })

        return isFlexible
          ? flexibleLabel
          : i18n.value.t('offers.values.only_on_the', {
            date: formatteDate
          })
      })

      const schedule = computed(() => {
        const {
          start_time: startTime,
          end_time: endTime,
          date
        } = offer.value[direction.value].time_slot

        const start = moment.value(`${date} ${startTime}`).format('LT')
        const end = moment.value(`${date} ${endTime}`).format('LT')

        return start === end ? start : `${start} - ${end}`
      })

      return {
        date,
        schedule
      }
    }
  })
</script>

<style lang="scss" scoped>
.offer-timeslots .offer-group-item:nth-child(1) {
  margin-right: 2rem;
  position: relative;
}
.offer-timeslots .offer-group-item:nth-child(1)::after {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 216, 216, var(--tw-bg-opacity));
  height: 100%;
  position: absolute;
  content: '';
  width: 1px;
  right: -1rem;
}
</style>
